import { LogoutButton } from "@/features/auth/components/logout.dropdown-menu-item";
import { useCurrentUser } from "@/features/auth/hooks/use-current-user";
import { cn } from "@/lib/utils";
import { Map as LeafletMap } from "leaflet";
import { useState } from "react";
import { MapContent } from "./map/map-content";
import { MapWrapper } from "./map/map-wrapper";
import { Overlay } from "./overlay";
import { Itineraries } from "./overlay/itineraries";
import { MoreActionsMobile } from "./overlay/more-actions.mobile";
import { OpenHelpButton } from "./overlay/open-help-button";
import { OpenPlannerButton } from "./overlay/open-planner-button";
import { Search } from "./overlay/search";
import { ZoomInOut } from "./overlay/zoom-in-out";

/**
 * Main react component to wrap all map functionality, loaders, assets, sub-components, etc.
 *
 * Consists of standard map content (geojson layers, markers, etc.) and the components overlay (i.e., search, route preview, etc.).
 *
 * __*Exposes map-container group metatag to be used as a tailwind query*__
 * @example <div className="bg-green-400 group-[.map-container]:bg-blue-400" />
 * @returns Map React Component
 */
export function App() {
  const { data } = useCurrentUser()
  const [map, setMap] = useState<LeafletMap | null>(null);
  if (!data || data.__tag === "UNAUTHENTICATED") {
    return
  }

  if (data.__tag === "AUTHENTICATED") {
    return (
      <div
        className={cn(
          "relative group map-container flex-1 grid overflow-hidden shadow-card",
          "animate-in fade-in-0 duration-1000"
        )}
      >
        <MapWrapper ref={setMap}>
          <MapContent />
        </MapWrapper>

        <Overlay map={map}>
          {{
            action: (
              <>
                <OpenPlannerButton map={map} className="hidden sm:flex !rounded-full w-16 h-16" />
                <MoreActionsMobile map={map} className="flex sm:hidden" >
                  <LogoutButton className="grid h-20 w-20 sm:hidden !rounded-full" />
                </MoreActionsMobile>
              </>
            ),
            secondaryAction: <ZoomInOut map={map} />,
            search: <Search map={map} />,
            card: <Itineraries map={map} />,
            help: <OpenHelpButton className="hidden sm:flex !rounded-full w-16 h-16" />,
            logout: <LogoutButton className="hidden sm:flex !rounded-full w-16 h-16" />,
          }}
        </Overlay>
      </div>
    )
  }

  return (
    <div
      className={cn(
        "relative group map-container flex-1 grid overflow-hidden shadow-card",
        "animate-in fade-in-0 duration-1000"
      )}
    >
      <MapWrapper ref={setMap}>
        <MapContent />
      </MapWrapper>

      <Overlay map={map}></Overlay>
    </div>
  )
}

