"use client"
import {
  useForm
} from "react-hook-form"
import {
  zodResolver
} from "@hookform/resolvers/zod"
import * as z from "zod"
import {
  Button
} from "@/components/ui/form/button"
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import {
  Input
} from "@/components/ui/form/input"
import { UseAuthorizationFunctionsResult } from "../hooks/use-authorization-actions"
import { emailSchema } from "../zod/schema.form"
import { errorToStringFormatter } from "@/lib/formatters/error-formatters"

const formSchema = z.object({
  user_email_address: emailSchema,
  user_password: z.string()
});

export function SignInForm({ actions }: { actions: UseAuthorizationFunctionsResult }) {
  const { signIn, initResetPassword } = actions
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
  })

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(async (values) => {
          await signIn(values.user_email_address, values.user_password)
        })}
        className="space-y-8 max-w-3xl mx-auto py-10"
      >

        <div className="text-foreground text-lg">Sign in to preview site.</div>

        <FormField
          control={form.control}
          name="user_email_address"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Username</FormLabel>
              <FormControl>
                <Input
                  placeholder="email@acme.com"
                  type="email"
                  {...field} />
              </FormControl>
              <FormDescription>This is your agency viewer email address.</FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="user_password"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Password</FormLabel>
              <FormControl>
                <Input type="password" placeholder="•••••••••••" {...field} />
              </FormControl>
              <FormDescription className="flex flex-row gap-2 items-baseline">
                Enter your password.{" "}
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        {
          !!actions.error && (
            <div className="text-destructive text-xs" > {errorToStringFormatter(actions.error)} </div>
          )
        }

        <div className="flex flex-col gap-2">
          <Button type="submit" variant={"default"}>Submit</Button>

          <Button variant={"link"} className="after:bg-muted-foreground after:w-full max-w-max px-0 text-xs" onClick={() => {
            const partialUsername = form.getValues().user_email_address
            initResetPassword(partialUsername)
          }}>
            Reset password?
          </Button>
        </div>
      </form>
    </Form>
  )
}


