import { z } from "zod";

const statewideServiceSchema = z.object({
  alias: z.string(),
  message: z.string(),
  url: z.string().optional(),
  phone: z.string().optional(),
  agencyid: z.string(),
})

export const getStatewideServicesErrorSchema = z.object({
  error: z.unknown(),
})

export const getStatewideServicesResultSuccessSchema = z.object({
  data: z.array(statewideServiceSchema).optional(),
  count: z.number().optional(),
})

export const getStatewideServicesResultSchema = z.union([
  getStatewideServicesErrorSchema,
  getStatewideServicesResultSuccessSchema,
])

export type AppGetStatewideServicesResult = z.infer<typeof getStatewideServicesResultSchema>
export type AppStatewideService = z.infer<typeof statewideServiceSchema>
