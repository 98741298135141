import useSWR from "swr"
import { usePlanner } from "./use-planner"
import { getStatewideServices } from "@/services/statewide-services/query"
import { minDelay } from "@/lib/timing"

const PLANNER_HOOK_KEY = "USE_PLANNER_STATEWIDE_SERVICES"
type INTERNAL__PlannerResult = ReturnType<typeof usePlanner>

export function usePlannerStatewideServices({
  original
}: {
  original: INTERNAL__PlannerResult
}) {
  const res = useSWR(
    [
      PLANNER_HOOK_KEY,
      original.data,
      original.isLoading,
    ],
    () => {
      if (
        original.isValidating ||
        original.isLoading ||
        (!!original.data?.extended?.itineraries?.valid && original.data.extended.itineraries.valid.length > 0)
      ) {
        return;
      }

      return minDelay(getStatewideServices(), 4250);
    },
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
    },
  )


  return res
}
