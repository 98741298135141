"use client"

import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/containers/tool-tip";
import { Button } from "@/components/ui/form/button";
import { useRouter } from "@/lib/navigation.router";
import { cn } from "@/lib/utils";
import { signOut } from "aws-amplify/auth";
import { LogOut } from "lucide-react";

export function LogoutButton({ className }: { className?: string }) {
  const router = useRouter();

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button
          // className={cn("flex flex-row gap-2 items-center w-full h-full", className)}

          variant={"outline"}
          className={cn(
            "relative grid place-items-center h-full w-16",
            "!rounded-none border-r-transparent duration-500 overflow-visible",
            className
          )}
          onClick={async () => {
            await signOut()

            router.navigateAndReplaceHistory("/login")
          }}
        >
          <LogOut className="size-4" />
          <span className="sr-only">Log out</span>
        </Button>
      </TooltipTrigger>

      <TooltipContent>
        Logout
      </TooltipContent>
    </Tooltip>

  )
}
