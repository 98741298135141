import { z } from "zod";
import { ValidateConfig } from "../zod/validator";
import { validatedFetch } from "../zod/validated-fetch";
import { env } from "@/env.setup";
import { getStatewideServicesResultSchema } from "./schema";

function INTERNAL__generateQuery<
  TZodValidation extends z.ZodTypeAny = z.ZodTypeAny,
>(
  url: string,
  validation: ValidateConfig<TZodValidation>["schema"]
) {
  return () => {
    return validatedFetch({
      url: url,
      schema: validation,
      init: {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    });
  }
}

export const getStatewideServices = INTERNAL__generateQuery(
  env.VITE_GET_STATEWIDE_SERVICES_URL,
  getStatewideServicesResultSchema,
)

