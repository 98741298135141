import { createRouter, parseSearchWith, stringifySearchWith } from "@tanstack/react-router";
import { decodeFromBinary, encodeToBinary } from "@/lib/encoding";
import { rootRoute } from "./root-route";
import { appRoute } from "./app-route";
import { helpRoute } from "./help-route";
import { loginRoute } from "./login-route";

const routeTree = rootRoute.addChildren([
  appRoute,
  helpRoute,
  loginRoute,
])

export const router = createRouter({
  routeTree,
  parseSearch: parseSearchWith((value) => JSON.parse(decodeFromBinary(value))),
  stringifySearch: stringifySearchWith((value) =>
    encodeToBinary(JSON.stringify(value)),
  ),
})
