import { LoginForm } from "@/features/auth/components/login.form";

export function LoginPage() {
  return (
    <div className="relative w-full min-h-svh overflow-hidden rounded-lg bg-background shadow-sm border-border">
      <div
        className="sticky w-full flex flex-col gap-3 justify-center items-center mt-32"
      >
        <LoginForm />
      </div>
    </div>
  )
}

