import { env } from "@/env.setup"

const config = {
  title: "Michigan Trip Planner",
  description: "",
  maintainer: "HNTB",
  links: {
    homepage: "https://www.hntb.com/",
    bitbucket: "https://bitbucket.org/hntbtsc/mdot-gtfs-trip-planner",
  },
  icons: [{ rel: "icon", url: "/favicon.ico" }],
} as const

export type SiteConfig = typeof config
export default config



/**
 * Default enabled state for county filter.
 */
export const DEFAULT_COUNTY_FILTER_STATE = env.MODE === "development" ? true : false;

export const DEFAULT_AUTHENTICATED_REDIRECT_URL = "/"
export const DEFAULT_UNAUTHENTICATED_REDIRECT_URL = "/login"
export const DEFAULT_UNAUTHORIZED_REDIRECT_URL = "/unauthorized"
