import { createRoute } from "@tanstack/react-router"
import { rootRoute } from "./root-route"
import { LoginPage } from "../+login.page"
import { tanstackRedirectUserAuthenticatedGuard } from "@/features/auth/lib/guards"

export const loginRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/login',
  component: LoginPage,
  beforeLoad: async () => {
    await tanstackRedirectUserAuthenticatedGuard()
  },
})


