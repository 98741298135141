import { useRouter as useTanstackRouter } from "@tanstack/react-router"

// ts-nocheck
export const useRouter = () => {
  const router = useTanstackRouter()

  return {
    ...router,
    navigateAndReplaceHistory: (href: string) => {
      router.history.flush();
      router.navigate({
        to: href
      });
    }
  }
}
