"use client"
import {
  useForm
} from "react-hook-form"
import {
  zodResolver
} from "@hookform/resolvers/zod"
import * as z from "zod"
import {
  Button
} from "@/components/ui/form/button"
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import {
  Input
} from "@/components/ui/form/input"
import { UseAuthorizationFunctionsResult } from "../hooks/use-authorization-actions"
import { emailSchema } from "../zod/schema.form"
import { errorToStringFormatter } from "@/lib/formatters/error-formatters"

const formSchema = z.object({
  user_email_address: emailSchema,
});

export function PasswordResetForm({ actions }: { actions: UseAuthorizationFunctionsResult }) {
  const { resetPassword, initWorkflow } = actions
  const previousUsername = actions.previousForm?.username
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      user_email_address: previousUsername
    }
  })

  return (
    <Form {...form}>

      <form
        onSubmit={
          form.handleSubmit(async (values) => {
            await resetPassword(values.user_email_address)
          })
        }
        className="space-y-8 max-w-3xl mx-auto py-10"
      >
        <div className="text-foreground text-lg">Password reset.</div>

        <FormField
          control={form.control}
          name="user_email_address"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Username</FormLabel>
              <FormControl>
                <Input
                  placeholder="email@acme.com"
                  type="email"
                  {...field} />
              </FormControl>
              <FormDescription>Email to send your confirmation number.</FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        {
          !!actions.error && (
            <div className="text-destructive text-xs" > {errorToStringFormatter(actions.error)} </div>
          )
        }

        <div className="flex flex-col gap-2">
          <Button type="submit">Send link</Button>

          <Button
            variant={"secondary"}
            onClick={() => {
              initWorkflow()
            }}>
            Go back to login
          </Button>
        </div>
      </form>
    </Form>
  )
}


