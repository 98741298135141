import { CommandGroup, CommandItem } from "@/components/ui/form/command"
import { minDelay } from "@/lib/timing"
import { aws } from "@/services/aws"
import { SearchForSuggestionsResult } from "@aws-sdk/client-location"
import { LoaderIcon } from "lucide-react"
import { PropsWithChildren } from "react"
import useSWR from "swr"

/**
 * Search text for all static options to preload
 */
const STATIC_OPTIONS = [
  {
    display: "Cherry Capital Airport",
    search: "Cherry Capital Airport, MI, 49686, USA",
  },
  {
    display: "Manistee County Blacker Airport",
    search: "Manistee County Blacker Airport, MI, 49660, USA",
  },
  {
    display: "Cowell Family Cancer Center",
    search: "Cancer Center, 217 S Madison St, Traverse City, MI 49684",
  }
] as const


export function LocationSearchStaticOptions({
  children,
  setValue,
}: PropsWithChildren<{
  search?: string
  setValue: (value: SearchForSuggestionsResult) => void
}>) {
  const { isLoading, error, data } = useSWR(
    "LOCATION_SEARCH_STATIC_OPTIONS",
    preLoadPlaces,
    {
      refreshWhenHidden: false,
      refreshWhenOffline: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  )

  if (isLoading) {
    return (
      <>
        <CommandGroup>
          <CommandGroup
            heading="Loading common destinations..."
            className="grid place-items-center mb-4"
          >
            <LoaderIcon className="w-4 h-4 animate-spin" />
          </CommandGroup>
        </CommandGroup>

        {children}
      </>
    )
  }

  if (error) {
    return (
      <>
        <CommandGroup heading={error?.message} />

        {children}
      </>
    )
  }

  if (!data) {
    return children;
  }

  return (
    <>
      {data && data.length > 0 && (
        <CommandGroup heading={"Regional Destinations"}>
          {data.map((o) => {
            if (!o) {
              return;
            }

            const { topResult, search } = o;

            return (
              <CommandItem
                key={topResult.Text}
                value={topResult.Text}
                className="text-base sm:text-sm"
                onSelect={() => {
                  setValue({
                    Text: search.display,
                    PlaceId: topResult.PlaceId,
                  })
                }}
              >
                {search.display}
              </CommandItem>
            )
          })}
        </CommandGroup>
      )}

      {children}
    </>
  )
}

async function preLoadPlaces() {
  const results = await minDelay(Promise.all(
    STATIC_OPTIONS.map(async (o) => {
      const res = await aws.query.suggest({
        Text: o.search,
      });

      return res.Results?.length && res.Results.length >= 1
        ? {
          topResult: res.Results[0],
          search: o,
        }
        : undefined;
    })
  ), 800)

  return results
}
