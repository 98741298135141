import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/containers/card";
import { Dialog, DialogContent, DialogTitle, DialogTrigger } from "@/components/ui/containers/dialog";
import { List, ListItem } from "@/components/ui/displays/list";
import { Button } from "@/components/ui/form/button";
import { Link } from "@/components/ui/primitives/link";
import { AppStatewideService } from "@/services/statewide-services/schema";
import { ArrowUpRight } from "lucide-react";

export function StatewideServiceCard({ alias, phone, url, message }: AppStatewideService) {
  return (
    <Card>
      <CardHeader className="p-0 pt-4 px-6 flex flex-row justify-between gap-1">
        <CardTitle className="text-base text-pretty">
          {alias}
        </CardTitle>
        <span>
          <Link
            variant={"link"}
            target="_blank"
            className="text-sm p-0 text-pretty"
            to={`${url}`}
          >{url}</Link>
        </span>
      </CardHeader>

      <CardContent className="p-0 px-3 pb-4">
        <Dialog>
          <DialogTrigger asChild>
            <Button variant={"ghost"} className="text-muted-foreground group details-group w-full flex flex-row gap-1 justify-start items-center [&>svg]:hover:translate-x-0.5 [&>svg]:hover:-translate-y-0.5">
              View details <ArrowUpRight className="size-3 transition-transform" />
            </Button>
          </DialogTrigger>

          <DialogContent>
            <DialogTitle>{alias}</DialogTitle>

            {message}

            <List className="text-xs">
              <ListItem>
                <span>Url</span>
                <span>
                  <Link
                    variant={"link"}
                    target="_blank"
                    className="p-0 text-pretty"
                    to={`${url}`}
                  >{url}</Link>
                </span>
              </ListItem>
              <ListItem>
                <span>Telephone</span>
                <span>
                  <a
                    className="hover:underline focus-within:underline"
                    href={`tel:${phone}`}
                  >{phone}</a>
                </span>
              </ListItem>
            </List>
          </DialogContent>
        </Dialog>
      </CardContent>
    </Card>
  )
}

