import * as React from "react"

import { cn } from "@/lib/utils"
import { Eye, EyeOff, Search } from "lucide-react"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../../containers/tool-tip"
import { Button } from "../button"
import { focusInput, focusRing } from "../../shared/component-state"

export type InputProps = React.InputHTMLAttributes<HTMLInputElement>

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    const [typeState, setTypeState] = React.useState(type)

    const isPassword = type === "password"
    const isSearch = type === "search"

    return (
      <div className="relative flex flex-row">
        <input
          type={typeState}
          className={cn(
            focusInput,
            "flex h-9 w-full rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-foreground placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50",
            isPassword ? "pe-9 rounded-e-none" : "",
            isSearch ? "pl-8" : "",
            className
          )}
          ref={ref}
          {...props}
        />

        {isSearch && (
          <div
            className={cn(
              "pointer-events-none absolute bottom-0 left-2 flex h-full items-center justify-center",
            )}
          >
            <Search
              className="text-muted-foreground size-3 shrink-0"
              aria-hidden="true"
            />
          </div>
        )}
        {isPassword && (
          <TooltipProvider delayDuration={0}>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  type="button"
                  onClick={() => {
                    setTypeState(typeState === "password" ? "text" : "password")
                  }}
                  variant={"outline"}
                  size="icon"
                  className={cn(focusRing, "h-9 w-10 rounded-none shadow-sm rounded-s-none rounded-e-lg border border-input border-l-transparent")}
                  aria-label={"Change password visibility"}
                >
                  {typeState === "password" ? (
                    <Eye aria-hidden="true" className="text-muted-foreground size-3 shrink-0" />
                  ) : (
                    <EyeOff aria-hidden="true" className="text-muted-foreground size-3 shrink-0" />
                  )}
                </Button>
              </TooltipTrigger>
              <TooltipContent className="border border-input bg-popover px-2 py-1 text-xs text-muted-foreground">
                Change password visibility
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}
      </div>
    )
  }
)
Input.displayName = "Input"

export { Input }
