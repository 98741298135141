import { SHOULD_APPLY_AUTH_GUARD } from "@/config/otp";
import { DEFAULT_UNAUTHENTICATED_REDIRECT_URL, DEFAULT_UNAUTHORIZED_REDIRECT_URL } from "@/config/site";
import { AppUserGroup } from "@/features/auth/zod/amplify.schema";
import { redirect } from "@tanstack/react-router";
import { getIsUserAuthenticatedFromServer, getIsUserAuthorizedFromServer } from "./session.amplify";

const INTERNAL__SHOULD_APPLY_AUTH_GUARD = SHOULD_APPLY_AUTH_GUARD;

/**
 * Redirects to the given url when no user session is currently stored
 * otherwise the current user is returned
 * 
 * @param url url to redirect to __*Default = '/login'*__
 * @returns current user or never
 */
export async function tanstackRedirectUserGuard(url = DEFAULT_UNAUTHENTICATED_REDIRECT_URL) {
  if (!INTERNAL__SHOULD_APPLY_AUTH_GUARD) {
    return
  }

  const isAuthenticated = await getIsUserAuthenticatedFromServer();
  if (!isAuthenticated) {
    throw redirect({ to: url })
  }

  return
}

/**
 * Redirects to the given url when a user session is currently stored
 * otherwise return
 * 
 * @param url url to redirect to __*Default = '/'*__
 */
export async function tanstackRedirectUserAuthenticatedGuard(url = '/') {
  if (!INTERNAL__SHOULD_APPLY_AUTH_GUARD) {
    throw redirect({ to: url })
  }

  const isAuthenticated = await getIsUserAuthenticatedFromServer();
  if (!isAuthenticated) {
    return;
  }

  throw redirect({ to: url })
}

/**
 * Redirects to the given url when no user session is currently stored
 * or that the current user has the proper group
 * otherwise return
 * 
 * @param group group to ensure user is a part of
 * @param url url to redirect to __*Default = '/'*__
 */
export async function tanstackRedirectUserAuthorizedGuard(group: AppUserGroup, url = DEFAULT_UNAUTHORIZED_REDIRECT_URL) {
  if (!INTERNAL__SHOULD_APPLY_AUTH_GUARD) {
    return;
  }

  const isAuthorized = await getIsUserAuthorizedFromServer(group);
  if (!isAuthorized) {
    throw redirect({ to: url })
  }

  return;
}

