import { createRootRoute, Outlet } from '@tanstack/react-router'
import { DevTools } from '../debug/dev-tools'
import { BaseLayout } from '../layout/base-layout'

export const rootRoute = createRootRoute({
  component: () => (
    <>
      <BaseLayout>
        <Outlet />
      </BaseLayout>

      <DevTools />
    </>
  ),
})
