import { Icons } from "@/components/ui/icons";
import { CardMessageWrapper } from "./itineraries-state.contianer";
import { AppItinerary } from "@/types/otp/+extended";
import Typography from "@/components/ui/typeography/typeography";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/containers/accordion";
import { ItineraryListContent } from "../itinerary-list/itinerary-list.content";
import { AppStatewideService } from "@/services/statewide-services/schema";
import { StatewideServiceCard } from "../statewide-service-card";

export function ItineraryCardNoRoutes({
  potentialFutureItineraries,
  potentialStatewideServices,
}: {
  potentialFutureItineraries?: AppItinerary[] | null,
  potentialStatewideServices?: AppStatewideService[] | null,
}) {
  return (
    <>
      <CardMessageWrapper
        className="mb-16"
        icon={<Icons.plan.noRoute />}
      >
        There are no routes between the selected locations with these settings. Please select again closer to the areas served by the pilot agencies or update the filter to generate different routes.

      </CardMessageWrapper>
      {potentialStatewideServices && potentialStatewideServices.length > 0 &&
        <>
          <Typography variant={"h5"}>
            Did you search for a long distance trip? One of these services may be applicable to you.
          </Typography>
          <Accordion type="single" collapsible className="w-full mb-12">
            <AccordionItem value="item-1">
              <AccordionTrigger>Statewide services</AccordionTrigger>
              <AccordionContent className="grid gap-2">
                {potentialStatewideServices.map((s) => {
                  return (
                    <StatewideServiceCard
                      key={s.agencyid + s.alias}
                      {...s}
                    />
                  )
                })}
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </>
      }

      {potentialFutureItineraries && potentialFutureItineraries.length > 0 &&
        <>
          <Typography variant={"h4"}>
            Are you flexible with time?
          </Typography>
          <Accordion type="single" collapsible className="w-full">
            <AccordionItem value="item-1">
              <AccordionTrigger>Future plans</AccordionTrigger>
              <AccordionContent>
                <ItineraryListContent itineraries={potentialFutureItineraries} />
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </>
      }
    </>
  )
}

