"use client"
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import {
  Button
} from "@/components/ui/form/button"
import {
  Input
} from "@/components/ui/form/input"
import { errorToStringFormatter } from "@/lib/formatters/error-formatters"
import {
  zodResolver
} from "@hookform/resolvers/zod"
import {
  useForm
} from "react-hook-form"
import * as z from "zod"
import { UseAuthorizationFunctionsResult } from "../hooks/use-authorization-actions"
import { passwordSchema } from "../zod/schema.form"

const formSchema = z.object({
  user_password: passwordSchema,
  user_password_confirmation: passwordSchema,
}).refine((data) => data.user_password === data.user_password_confirmation, {
  path: ['user_password_confirmation'],
  message: 'Passwords must match'
});

export function PasswordUpdateForm({ actions }: { actions: UseAuthorizationFunctionsResult }) {
  const { confirmSignIn } = actions
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
  })

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(async (values) => {
          await confirmSignIn(values.user_password)
        })}
        className="space-y-8 max-w-3xl mx-auto py-10"
      >

        <div className="text-foreground text-lg">Update password.</div>

        <FormField
          control={form.control}
          name="user_password"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Password</FormLabel>
              <FormControl>
                <Input type="password" placeholder="•••••••••••" {...field} />
              </FormControl>
              <FormDescription>Enter your password.</FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="user_password_confirmation"
          rules={{
            deps: "user_password"
          }}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Password Confirmation</FormLabel>
              <FormControl>
                <Input type="password" placeholder="•••••••••••" {...field} />
              </FormControl>
              <FormDescription>Confirm your chosen password.</FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />


        {
          !!actions.error && (
            <div className="text-destructive text-xs" > {errorToStringFormatter(actions.error)} </div>
          )
        }

        <Button type="submit">Update password</Button>
      </form>
    </Form>
  )
}

