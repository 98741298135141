import { usePlanner } from "@/hooks/use-planner";
import { ItineraryCardError } from "../states/itinerary.error";
import { ItineraryCardEmpty } from "../states/itinerary.empty";
import { ItineraryCardNoRoutes } from "../states/itinerary.no-routes";
import { ItineraryListContent } from "./itinerary-list.content";
import { ItineraryCardLoading } from "../states/itinerary.loading";
import { usePlannerStatewideServices } from "@/hooks/use-planner.statewide-services";

export function ItineraryList() {
  const planner = usePlanner();
  const { data, error, isLoading, hasValidQuery } = planner;
  const services = usePlannerStatewideServices({ original: planner })

  if (!!error || !!services.error) {
    return <ItineraryCardError error={error ?? services.error} />
  }

  if (isLoading || services.isLoading) {
    return <ItineraryCardLoading loading={!isLoading && services.isLoading ? "SERVICES" : "UNKNOWN"} />
  }

  if (!hasValidQuery) {
    return <ItineraryCardEmpty />
  }

  if ((!data?.extended.itineraries?.valid || data.extended.itineraries.valid.length <= 0)
  ) {
    return <ItineraryCardNoRoutes potentialFutureItineraries={data?.extended.itineraries?.future} potentialStatewideServices={services.data && "data" in services.data ? services.data.data : undefined} />;
  }

  return (
    <div className="mr-3 pb-4">
      <ItineraryListContent itineraries={data.extended.itineraries?.valid} />

      {/** As per future request, this code has been hidden */}
      {/** {data.extended.itineraries?.future && data.extended.itineraries.future.length > 0 &&
                <Accordion type="single" collapsible className="w-full">
                    <AccordionItem value="item-1">
                        <AccordionTrigger>Future plans...</AccordionTrigger>
                        <AccordionContent>
                            <PlanSelectList itineraries={data.extended.itineraries.future} />
                        </AccordionContent>
                    </AccordionItem>
                </Accordion>
            } */}
    </div>
  )

}
