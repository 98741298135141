import { env } from "@/env.setup";
import { generatePlanForNow, generatePlannerFetch, generatePlannerSlimFetch } from "./queries/generate-planner-fetch";
import { planResponseSchema } from "./validations/planner";
import { AGENCIES, ITINERARY_CARD_QUERY, ROUTES_AND_STOPS_QUERY, ROUTES_AND_AGENCIES_QUERY } from "./queries.graphql";
import { generateGraphqlQuery } from './queries/generate-otp-query.graphql'

import { ZodAny, z } from "zod";
import { GroupedRoutes, setupRoutes } from "./routes";
import { generatePlannerQuery } from "./queries/generate-planner-query.graphql";
import { agenciesGraphqlResponse } from "./validations/agency.graphql";
import { generateRoutesQuery } from "./queries/generate-routes-query.graphql";
import { APP_OTP_GRAPHQL_URL } from "@/config/otp";

let routePromise: Promise<GroupedRoutes> | undefined
export const nullRoutes = {
  medical: [],
  medicalIds: "",
  standard: [],
  standardIds: "",
  all: [],
} satisfies GroupedRoutes

export const otp = {
  planner: generatePlannerSlimFetch({
    schema: planResponseSchema,
    url: env.VITE_OTP_PLAN_URL
  }),
  plannerFull: generatePlannerFetch({
    schema: planResponseSchema,
    url: env.VITE_OTP_PLAN_URL
  }),

  plannerForNow: generatePlanForNow({
    schema: planResponseSchema,
    url: env.VITE_OTP_PLAN_URL
  })
}

const planner = generatePlannerQuery(
  ITINERARY_CARD_QUERY,
  "QUERY",
  APP_OTP_GRAPHQL_URL,
)

const agencies = generateGraphqlQuery(
  AGENCIES,
  "AGENCIES",
  APP_OTP_GRAPHQL_URL,
  agenciesGraphqlResponse,
)

const routes = generateRoutesQuery(
  ROUTES_AND_AGENCIES_QUERY,
  "ROUTES",
  APP_OTP_GRAPHQL_URL,
)

export const otpGraphql: {
  planner: typeof planner
  stops: () => Promise<z.infer<ZodAny>>
  agencies: typeof agencies
  routes: typeof routes
  setup: () => void
  teardown: () => void

  routesCache: GroupedRoutes | undefined
} = {
  planner: planner,
  agencies: agencies,
  routes: routes,

  stops: generateGraphqlQuery(
    ROUTES_AND_STOPS_QUERY,
    "ROUTES_AND_STOPS",
    APP_OTP_GRAPHQL_URL,
    z.any(),
  ),

  setup: async () => {
    routePromise = routePromise ? routePromise : setupRoutes()
    otpGraphql.routesCache = await routePromise
  },
  teardown: () => {
    otpGraphql.routesCache = nullRoutes
    routePromise = undefined
  },

  routesCache: nullRoutes,
}
