"use client"
// import { useRouter } from "@/lib/navigation.router"
import { useEffect } from "react"
import { isResetPasswordOutput, isSignInOutput, useAuthorizationFunctions, UseAuthorizationFunctionsResult } from "../hooks/use-authorization-actions"
import { PasswordResetForm } from "./password-reset.form"
import { PasswordResetConfirmationForm } from "./password-reset.confirmation-form"
import { PasswordUpdateForm } from "./password-update.form"
import { SignInForm } from "./sign-in.form"
import { ResetPasswordOutput, SignInOutput } from "aws-amplify/auth"
import { useRouter } from "@tanstack/react-router"

export function LoginForm() {
  const actions = useAuthorizationFunctions()
  const router = useRouter()

  useEffect(() => {
    if (isResetPasswordOutput(actions.state) && actions.state.isPasswordReset) {
      router.navigate({
        to: '/'
      })
    }

    if (isSignInOutput(actions.state) && actions.state.isSignedIn) {
      router.navigate({
        to: '/'
      })
    }
  }, [actions.state, router])

  if (actions.state === 'RESET_PASSWORD') {
    return <PasswordResetForm actions={actions} />
  }

  if (isSignInOutput(actions.state)) {
    return INTERNAL__getSignInStepForm(actions, actions.state)
  }

  if (isResetPasswordOutput(actions.state)) {
    return INTERNAL__getResetPasswordForm(actions, actions.state)
  }

  return <SignInForm actions={actions} />;
}

function INTERNAL__getSignInStepForm(actions: UseAuthorizationFunctionsResult, state: SignInOutput) {
  if (state.nextStep.signInStep === 'RESET_PASSWORD') {
    return <PasswordResetForm actions={actions} />
  }

  if (state.nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
    return <PasswordUpdateForm actions={actions} />
  }

  return <SignInForm actions={actions} />
}

function INTERNAL__getResetPasswordForm(actions: UseAuthorizationFunctionsResult, state: ResetPasswordOutput) {
  if (state.nextStep.resetPasswordStep === 'CONFIRM_RESET_PASSWORD_WITH_CODE') {
    return <PasswordResetConfirmationForm actions={actions} />
  }

  return null;
}
