import markerSDK, { MarkerSdk } from "@marker.io/browser";
import { env } from '../../env.setup'

let widgetPromise: Promise<MarkerSdk> | undefined;

export const markerIo: {
    setup: () => void,
    teardown: () => void,
    widget: MarkerSdk | null
} = {
    widget: null,

    setup: async () => {
        widgetPromise = widgetPromise ? widgetPromise : markerSDK.loadWidget({
            project: env.VITE_MARKER_IO_PROJECT_ID,
        })

        markerIo.widget = await widgetPromise
    },
    teardown: () => {
        markerIo.widget = null;
        widgetPromise = undefined;
    },
} as const