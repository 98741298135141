"use client"
import {
  Button
} from "@/components/ui/form/button"
import { isResetPasswordOutput, UseAuthorizationFunctionsResult } from "../hooks/use-authorization-actions"
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { InputOTP, InputOTPGroup, InputOTPSeparator, InputOTPSlot } from "@/components/ui/input-otp";
import { Input } from "@/components/ui/form/input";
import { emailSchema, passwordSchema } from "../zod/schema.form";
import { errorToStringFormatter } from "@/lib/formatters/error-formatters";

const formSchema = z.object({
  user_potential_username: emailSchema,
  user_new_password: passwordSchema,
  user_one_time_code: z.string().length(6)
    .refine((password) => /[0-9]/.test(password), { message: "Code must be only numbers" }),
});

export function PasswordResetConfirmationForm({ actions }: { actions: UseAuthorizationFunctionsResult }) {
  const { confirmReset } = actions
  const previousUsername = actions.previousForm?.username
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      user_potential_username: previousUsername,
      user_new_password: undefined,
      user_one_time_code: undefined,
    }
  })

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(async (values) => {
          await confirmReset(values.user_potential_username, values.user_new_password, values.user_one_time_code)
        })}
        className="space-y-8 max-w-3xl mx-auto py-10"
      >
        <div className="text-foreground text-lg">A confirmation code has been sent to {
          isResetPasswordOutput(actions.state)
            ? actions.state.nextStep.codeDeliveryDetails.destination
            : ""
        }.</div>

        <div className="text-muted-foreground text-xs">Remember to check your <b>spam</b> folder.</div>

        {!previousUsername && (
          <FormField
            control={form.control}
            name="user_potential_username"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Username confirmation</FormLabel>
                <FormControl>
                  <Input
                    placeholder="email@acme.com"
                    type="email"
                    {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        )}

        <FormField
          control={form.control}
          name="user_new_password"
          render={({ field }) => (
            <FormItem>
              <FormLabel>New password</FormLabel>
              <FormControl>
                <Input type="password" placeholder="•••••••••••" {...field} />
              </FormControl>
              <FormDescription>Enter your password.</FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="user_one_time_code"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Confirmation code</FormLabel>
              <FormControl>
                <InputOTP id="one_time_code" maxLength={6} {...field}>
                  <InputOTPGroup>
                    <InputOTPSlot index={0} />
                    <InputOTPSlot index={1} />
                    <InputOTPSlot index={2} />
                  </InputOTPGroup>
                  <InputOTPSeparator />
                  <InputOTPGroup>
                    <InputOTPSlot index={3} />
                    <InputOTPSlot index={4} />
                    <InputOTPSlot index={5} />
                  </InputOTPGroup>
                </InputOTP>
              </FormControl>
              <FormDescription>Enter your one-time-use confirmation code.</FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />

        {
          !!actions.error && (
            <div className="text-destructive text-xs" > {errorToStringFormatter(actions.error)} </div>
          )
        }

        <Button type="submit">Update password</Button>
      </form>
    </Form>
  )
}

