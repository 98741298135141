import { Icons } from "@/components/ui/icons";
import { CardMessageWrapper } from "./itineraries-state.contianer";

export function ItineraryCardLoading({
  loading
}: {
  loading: "UNKNOWN" | "SERVICES"
}) {
  if (loading === 'SERVICES') {
    return (
      <CardMessageWrapper
        className="animate-pulse"
        icon={<Icons.plan.loading />}
      >
        Searching through various other servers...
      </CardMessageWrapper>
    )
  }

  return <CardMessageWrapper
    className="animate-pulse"
    icon={<Icons.plan.loading />}
  >
    We are currently working on finding you the best routes. Hold on while we make some calculations!
  </CardMessageWrapper>
}
