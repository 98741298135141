import { autoSignIn, confirmResetPassword, confirmSignIn, ConfirmSignInOutput, resetPassword, ResetPasswordOutput, signIn, SignInOutput, signOut } from "aws-amplify/auth";
import { useState } from "react";

type INTERNAL__AuthorizationState = 'RESET_PASSWORD' | SignInOutput | ConfirmSignInOutput | ResetPasswordOutput | undefined

// ts-nocheck
export function useAuthorizationFunctions() {
  const [state, setState] = useState<INTERNAL__AuthorizationState>()
  const [previousForm, setPreviousForm] = useState<{ username?: string, password?: string } | undefined>()
  const [error, setError] = useState<Error | string | undefined>()

  return {
    state,
    error,
    previousForm,
    initWorkflow: () => setState(undefined),
    initResetPassword: (username?: string) => {
      setPreviousForm({
        username: username,
      })
      setState('RESET_PASSWORD');
      setError(undefined)
    },
    autoSignIn: async () => {
      return await autoSignIn()
    },
    confirmReset: async (username: string, password: string, confirmationCode: string) => {
      try {
        await confirmResetPassword({
          confirmationCode: confirmationCode,
          username: username,
          newPassword: password,
        })

        setPreviousForm({
          username: username,
          password: password,
        })

        setState(undefined)
        setError(undefined)
      } catch (error) {
        if (error instanceof Error || typeof error === 'string') {
          setError(error)
        }
      }
    },
    confirmSignIn: async (challengeResponse: string) => {
      try {
        const res = await confirmSignIn({
          challengeResponse: challengeResponse
        })

        setState(res)
        setError(undefined)
      } catch (error) {
        if (error instanceof Error || typeof error === 'string') {
          setError(error)
        }
      }
    },
    resetPassword: async (username: string) => {
      try {
        const res = await resetPassword({
          username: username,
        })

        setPreviousForm({
          username: username
        })

        setState(res)
        setError(undefined)
      } catch (error) {
        if (error instanceof Error || typeof error === 'string') {
          setError(error)
        }
      }
    },
    signIn: async (username: string, password: string) => {
      try {
        // Sign out to clear Cognito based cookies. Eliminate duplicate cookies issues (https://github.com/aws-amplify/amplify-js/issues/5330)
        await signOut()
        const res = await signIn({
          username: username,
          password: password,
        })

        setPreviousForm({
          username: username
        })

        setState(res)
        setError(undefined)
      } catch (error) {
        if (error instanceof Error || typeof error === 'string') {
          setError(error)
        }
      }
    },
  }
}

export type UseAuthorizationFunctionsResult = ReturnType<typeof useAuthorizationFunctions>
export type ResetPasswordResult = Awaited<ReturnType<typeof resetPassword>>

export function isSignInOutput(check: INTERNAL__AuthorizationState): check is SignInOutput {
  return typeof check === 'object' && !!check.nextStep && 'signInStep' in check.nextStep
}

export function isResetPasswordOutput(check: INTERNAL__AuthorizationState): check is ResetPasswordOutput {
  return typeof check === 'object' && !!check.nextStep && 'resetPasswordStep' in check.nextStep
}

