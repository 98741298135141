"use server";

import { fetchAuthSession, getCurrentUser } from "aws-amplify/auth";
import { AuthSession, signOut } from 'aws-amplify/auth';
import { AppUserGroup } from '@/features/auth/zod/amplify.schema';

export const getCurrentSessionFromServer = async () => {
  const currentSession = fetchAuthSession()
  return currentSession;
};

export const getCurrentUserFromServer = async () => {
  const user = await getCurrentUser();
  return {
    name: user.signInDetails?.loginId ?? 'N/A'
  }
};

export const getIsUserAuthenticatedFromServer = async () => {
  try {
    const session = await fetchAuthSession()
    return !!session.tokens
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const getIsUserAuthorizedFromServer = async (group: AppUserGroup) => {
  try {
    const session = await fetchAuthSession()
    const groups = INTERNAL__getSessionGroups(session)
    return !!session.tokens && !!groups?.find((g) => g === group)
  } catch (error) {
    console.error(error);
    return false;
  }
};


export const signOutFromServer = async () => {
  return signOut()
};

const INTERNAL__sessionGroupKey = 'cognito:groups'

function INTERNAL__getSessionGroups(session: AuthSession): AppUserGroup[] | undefined {
  const groups = session.tokens?.accessToken.payload[INTERNAL__sessionGroupKey]
  if (!groups) {
    return
  }

  if (typeof groups === 'object') {
    return groups as AppUserGroup[]
  }

  if (typeof groups === 'string') {
    return JSON.parse(groups) as AppUserGroup[]
  }
}

