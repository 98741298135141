import { RelativeDirectionSchema } from "@/services/otp/validations/planner.graphql"
import {
  Bike,
  CarFront,
  Check,
  ChevronDown,
  CircleAlert,
  CircleCheck,
  CircleDashed,
  CirclePlus,
  Dot,
  Download,
  Eye,
  EyeOff,
  Maximize,
  Minus,
  MoreVertical,
  Plus,
  Search,
  Share,
  TrainFront,
  TriangleAlert,
  Upload,
  X,
  type LucideProps as IconProps,
  ArrowDownUp,
  MapPinned,
  ServerOff,
  RouteOff,
  ChevronLeft,
  ArrowLeftRight,
  Hospital,
  Calendar,
  ChevronRight,
  Route,
  CalendarDays,
  Link,
  Book,
  ArrowUp,
  SquareChevronUp,
  CornerUpLeft,
  CornerUpRight,
  ArrowUpLeft,
  ArrowUpRight,
  Undo2,
  RotateCw,
  RotateCcw,
  Timer,
  Hourglass,
  Phone,
  Loader2,
} from "lucide-react"
import type { ReactElement } from "react"

import { cn } from "@/lib/utils";

import {
  Ferry,
  Bus,
  Walk
} from './icons.custom'

export const Icons = {
  actions: {
    back: (props: IconProps) => <ChevronLeft {...props} absoluteStrokeWidth />,
    close: (props: IconProps) => <X {...props} absoluteStrokeWidth />,
    share: (props: IconProps) => <Share {...props} absoluteStrokeWidth />,
    download: (props: IconProps) => <Download {...props} absoluteStrokeWidth />,
    upload: (props: IconProps) => <Upload {...props} absoluteStrokeWidth />,
    expand: (props: IconProps) => <Maximize {...props} absoluteStrokeWidth />,
    more: (props: IconProps) => <MoreVertical {...props} absoluteStrokeWidth />,
    swap: (props: IconProps) => <ArrowDownUp {...props} absoluteStrokeWidth />,
    swapHorizontal: (props: IconProps) => <ArrowLeftRight {...props} absoluteStrokeWidth />,
  },
  input: {
    error: (props: IconProps) => <TriangleAlert {...props} absoluteStrokeWidth />,
    append: (props: IconProps) => <CirclePlus {...props} absoluteStrokeWidth />,
    selected: (props: IconProps) => <Check {...props} absoluteStrokeWidth />,
    passwordVisible: (props: IconProps) => <Eye {...props} absoluteStrokeWidth />,
    passwordHidden: (props: IconProps) => <EyeOff {...props} absoluteStrokeWidth />,
    plus: (props: IconProps) => <Plus {...props} absoluteStrokeWidth />,
    minus: (props: IconProps) => <Minus {...props} absoluteStrokeWidth />,
    search: (props: IconProps) => <Search {...props} absoluteStrokeWidth />,
    expand: (props: IconProps) => <ChevronDown {...props} absoluteStrokeWidth />,
  },
  alerts: {
    error: ({ className, ...props }: IconProps) => <TriangleAlert className={cn("w-3 h-3", className)} {...props} absoluteStrokeWidth />,
    success: ({ className, ...props }: IconProps) => <CircleCheck className={cn("w-3 h-3", className)} {...props} absoluteStrokeWidth />,
    default: ({ className, ...props }: IconProps) => <CircleAlert className={cn("w-3 h-3", className)} {...props} absoluteStrokeWidth />,
  },
  plan: {
    loading: ({ className, ...props }: IconProps) => <Loader2 className={cn("w-32 h-32 animate-spin stroke-primary stroke-1 duration-1000", className)} {...props} absoluteStrokeWidth />,
    empty: ({ className, ...props }: IconProps) => <MapPinned className={cn("w-32 h-32", className)} {...props} absoluteStrokeWidth />,
    error: ({ className, ...props }: IconProps) => <ServerOff className={cn("w-32 h-32", className)} {...props} absoluteStrokeWidth />,
    noRoute: ({ className, ...props }: IconProps) => <RouteOff className={cn("w-32 h-32", className)} {...props} absoluteStrokeWidth />,
  },
  itinerary: {
    car: ({ className, ...props }: IconProps) => <CarFront className={cn("w-4 h-4", className)} {...props} absoluteStrokeWidth />,
    bike: ({ className, ...props }: IconProps) => <Bike className={cn("w-4 h-4", className)} {...props} absoluteStrokeWidth />,
    train: ({ className, ...props }: IconProps) => <TrainFront className={cn("w-4 h-4", className)} {...props} absoluteStrokeWidth />,
    bus: ({ className, ...props }: IconProps) => <Bus className={cn("w-4 h-4", className)} {...props} />,
    rail: ({ className, ...props }: IconProps) => <Bus className={cn("w-4 h-4", className)} {...props} />,
    walk: ({ className, ...props }: IconProps) => <Walk className={cn("w-4 h-4", className)} {...props} />,
    ferry: ({ className, ...props }: IconProps) => <Ferry className={cn("w-4 h-4", className)} {...props} />,

    stop: (props: IconProps) => <Dot {...props} absoluteStrokeWidth />,
    move: ({ className, ...props }: IconProps) => <CircleDashed className={cn("w-4 h-4 stroke-2", className)} {...props} absoluteStrokeWidth />,
    transfer: ({ className, ...props }: IconProps) => <ChevronRight className={cn("w-4 h-4 stroke-2", className)} {...props} absoluteStrokeWidth />,

    indicator: {
      medical: ({ className, ...props }: IconProps) => <Hospital className={cn("h-4 w-4 md:h-5 md:w-5", className)} {...props} absoluteStrokeWidth />,
      callAhead: ({ className, ...props }: IconProps) => <Hourglass className={cn("h-4 w-4 md:h-5 md:w-5", className)} {...props} absoluteStrokeWidth />,
      scheduledDayOf: ({ className, ...props }: IconProps) => <Calendar className={cn("h-4 w-4 md:h-5 md:w-5", className)} {...props} absoluteStrokeWidth />,
      estimatedTimes: ({ className, ...props }: IconProps) => <Timer className={cn("h-4 w-4 md:h-5 md:w-5", className)} {...props} absoluteStrokeWidth />,
      multipleTransfers: ({ className, ...props }: IconProps) => <ArrowLeftRight className={cn("h-4 w-4 md:h-5 md:w-5", className)} {...props} absoluteStrokeWidth />,

      phone: ({ className, ...props }: IconProps) => <Phone className={cn("h-4 w-4 md:h-5 md:w-5", className)} {...props} absoluteStrokeWidth />,
      agencyUrl: ({ className, ...props }: IconProps) => <Link className={cn("h-4 w-4 md:h-5 md:w-5", className)} {...props} absoluteStrokeWidth />,
      flex: ({ className, ...props }: IconProps) => <Route className={cn("h-4 w-4 md:h-5 md:w-5", className)} {...props} absoluteStrokeWidth />,
      departure: ({ className, ...props }: IconProps) => <Hourglass className={cn("h-4 w-4 md:h-5 md:w-5", className)} {...props} absoluteStrokeWidth />,
      booking: ({ className, ...props }: IconProps) => <Book className={cn("h-4 w-4 md:h-5 md:w-5", className)} {...props} absoluteStrokeWidth />,
      operatingHours: ({ className, ...props }: IconProps) => <CalendarDays className={cn("h-4 w-4 md:h-5 md:w-5", className)} {...props} absoluteStrokeWidth />,
      link: ({ className, ...props }: IconProps) => <Link className={cn("h-3 w-3 md:h-3 md:w-3", className)} {...props} absoluteStrokeWidth />,
    },
    directions: {
      DEPART: ({ className, ...props }: IconProps) => <ArrowUp className={cn("h-4 w-4 md:h-5 md:w-5", className)} {...props} absoluteStrokeWidth />,
      CONTINUE: ({ className, ...props }: IconProps) => <ArrowUp className={cn("h-4 w-4 md:h-5 md:w-5", className)} {...props} absoluteStrokeWidth />,
      ENTER_STATION: ({ className, ...props }: IconProps) => <ArrowUp className={cn("h-4 w-4 md:h-5 md:w-5", className)} {...props} absoluteStrokeWidth />,
      EXIT_STATION: ({ className, ...props }: IconProps) => <ArrowUp className={cn("h-4 w-4 md:h-5 md:w-5", className)} {...props} absoluteStrokeWidth />,
      FOLLOW_SIGNS: ({ className, ...props }: IconProps) => <ArrowUp className={cn("h-4 w-4 md:h-5 md:w-5", className)} {...props} absoluteStrokeWidth />,
      ELEVATOR: ({ className, ...props }: IconProps) => <SquareChevronUp className={cn("h-4 w-4 md:h-5 md:w-5", className)} {...props} absoluteStrokeWidth />,
      HARD_LEFT: ({ className, ...props }: IconProps) => <CornerUpLeft className={cn("h-4 w-4 md:h-5 md:w-5", className)} {...props} absoluteStrokeWidth />,
      HARD_RIGHT: ({ className, ...props }: IconProps) => <CornerUpRight className={cn("h-4 w-4 md:h-5 md:w-5", className)} {...props} absoluteStrokeWidth />,
      LEFT: ({ className, ...props }: IconProps) => <CornerUpLeft className={cn("h-4 w-4 md:h-5 md:w-5", className)} {...props} absoluteStrokeWidth />,
      RIGHT: ({ className, ...props }: IconProps) => <CornerUpRight className={cn("h-4 w-4 md:h-5 md:w-5", className)} {...props} absoluteStrokeWidth />,
      SLIGHTLY_LEFT: ({ className, ...props }: IconProps) => <ArrowUpLeft className={cn("h-4 w-4 md:h-5 md:w-5", className)} {...props} absoluteStrokeWidth />,
      SLIGHTLY_RIGHT: ({ className, ...props }: IconProps) => <ArrowUpRight className={cn("h-4 w-4 md:h-5 md:w-5", className)} {...props} absoluteStrokeWidth />,
      UTURN_LEFT: ({ className, ...props }: IconProps) => <Undo2 className={cn("h-4 w-4 -rotate-90 md:h-5 md:w-5", className)} {...props} absoluteStrokeWidth />,
      UTURN_RIGHT: ({ className, ...props }: IconProps) => <Undo2 className={cn("h-4 w-4 rotate-90 -scale-x-100 md:h-5 md:w-5", className)} {...props} absoluteStrokeWidth />,
      CIRCLE_CLOCKWISE: ({ className, ...props }: IconProps) => <RotateCw className={cn("h-4 w-4 md:h-5 md:w-5", className)} {...props} absoluteStrokeWidth />,
      CIRCLE_COUNTERCLOCKWISE: ({ className, ...props }: IconProps) => <RotateCcw className={cn("h-4 w-4 md:h-5 md:w-5", className)} {...props} absoluteStrokeWidth />,
    } satisfies Record<RelativeDirectionSchema, ({ className, ...props }: IconProps) => ReactElement>
  }
} as const
