import { AwsRum, AwsRumConfig } from 'aws-rum-web';
import { env } from '@/env.setup';

export const initRUM = () => {
  try {
    const config: AwsRumConfig = {
      sessionSampleRate: 1,
      identityPoolId: env.VITE_AWS_RUM_IDENTITY_POOL_ID,
      endpoint: env.VITE_AWS_RUM_ENDPOINT,
      telemetries: ["performance", "errors", "http"],
      allowCookies: true,
      enableXRay: true
    };

    const APPLICATION_ID: string = env.VITE_AWS_RUM_APP_ID;
    const APPLICATION_VERSION: string = '1.0.0';
    const APPLICATION_REGION: string = env.VITE_AWS_RUM_REGION;

    new AwsRum(
      APPLICATION_ID,
      APPLICATION_VERSION,
      APPLICATION_REGION,
      config
    );
  } catch (error) {
    console.error(error)
  }
}